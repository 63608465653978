<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <!-- <div class="SDtip">
        {{ $t("sciencePopularization.storageDevelopment.SD_tip") }}
      </div> -->
      <!-- <vue-seamless-scroll
        :data="memorabilia"
        :class-option="options"
        class="seamless-warp"
      >
        <ul>
          <li v-for="item in memorabilia" :key="item.index">
            <img :src="item.image" alt="" />
          </li>
        </ul>
      </vue-seamless-scroll> -->
      <div class="imgList" @mousewheel="handleScroll" @mouseover="changeActive()" @mouseout="removeActive()">
        <div class="imgItem">
          <!--  -->
          <img :src="item.image" alt="" v-for="item in memorabilia" :key="item.index" mode="heightFix"
            :style="'height:' + imgHeight + 'px'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-行业科普-储存器发展大事记
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-17 15:59
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "行业科普",
          title_en: "Industry science popularization",
          path: "sciencePopularization",
        },
        {
          title_zh: "储存器发展大事记",
          title_en: "Memorabilia of storage development",
          path: "",
        },
      ],
      memorabilia: [],
      imgWidth: "",
      imgHeight: "",

      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
    };
  },
  // computed: {
  //   options() {
  //     return {
  //       // 滚动速度 , 数值越大滚动越快
  //       step: 0.8,
  //       // 滚动的数据条数
  //       limitMoveNum: 2,
  //       // 鼠标点击停止滚动, 默认true
  //       hoverStop: true,
  //       // 按住鼠标停止滚动, 默认true
  //       openTouch: true,
  //       // 滚动方向 , 0 向下 , 1 向上 , 2 向左 , 3 向右
  //       direction: 2,
  //       // 单步运动停止的高度(默认是0, 无缝不停止的滚动,direction是0或1)
  //       // 就是滚多高停一下,再滚多高又停一下,停的时间me就是waitTi
  //       // 这个高度,可以F12审查元素用箭头放在字上面看li的高度是多少
  //       singleHeight: 32,
  //       // 单步运动停止的宽度(默认是0, 无缝不停止的滚动,direction是2或3)
  //       singleWidth: 0,
  //       // 单步运动停止的时间 (默认值1000ms)
  //       waitTime: 0,
  //       // 开启数据实时监控刷新dom
  //       openWatch: true,
  //     };
  //   },
  // },
  mounted() {
    this.getMemorabilia();
    var that = this;
    // <!--把window.onresize事件挂在到mounted函数上-->
    that.windowHeight =
      document.documentElement.clientHeight || document.body.clientHeight;
    window.onresize = () => {
      return (() => {
        that.windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
      })();
    };
    console.log("windowHeight", this.windowHeight);

    console.log("headBoxTwo", this.$(".headBoxTwo").height());
    console.log("position", this.$(".position").height());
    this.imgHeight =
      this.windowHeight -
      this.$(".headBoxTwo").height() -
      this.$(".position").height() -
      19;
  },
  watch: {
    windowHeight(val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
      this.imgHeight =
        this.windowHeight -
        this.$(".headBoxTwo").height() -
        this.$(".position").height() -
        19;
      console.log("imgHeight", this.imgHeight);
    },
    // windowWidth(val) {
    //   let that = this;
    //   console.log("实时屏幕宽度：", val, that.windowWidth);
    // },
  },
  methods: {
    /**
     * 大事记
     * 刘嘉鑫
     * 2022-8-25
     */
    getMemorabilia() {
      this.$request({
        url: "/Index/storageList",
      }).then((res) => {
        console.log("大事记", res);
        this.memorabilia = res.img;

        let imgObj = new Image();
        imgObj.src = res.img[0].image;
        imgObj.onload = () => {
          // resolve({
          //   width: imgObj.width,
          //   height: imgObj.height,
          // });
          // console.log(imgObj.width);
          this.imgWidth = imgObj.width;
        };
      });
    },

    handleScroll(e) {
      console.log(e.deltaY, e.deltaX)
      const tabBar = this.$el.querySelector(".imgList");
      //下面的实现的是内部元素横向滚动，前提设置好内部元素横向的滚动样式了
      let leftScroll = tabBar.scrollLeft;
      // 0----down  1----up
      if (e.deltaY == -0) {
        var direction = e.deltaX > 0 ? "0" : "1";
        if (direction === "0") {
          tabBar.scrollLeft += 10;
        } else {
          tabBar.scrollLeft -= 10;
        }
      } else {
        var direction = e.deltaY > 0 ? "0" : "1";
        if (direction === "0") {
          tabBar.scrollLeft += 50;
        } else {
          tabBar.scrollLeft -= 50;
        }
      }
      if (leftScroll == tabBar.scrollLeft) {
        this.removeActive();
      }
    },

    /**
     * 鼠标移入禁止页面滚动
     * 刘嘉鑫
     * 2022-8-20
     */
    changeActive() {
      document.querySelector("body").style.overflow = "hidden";
    },
    /**
     * 鼠标移出页面滚动
     * 刘嘉鑫
     * 2022-8-20
     */
    removeActive() {
      document.querySelector("body").style.overflow = "";
    },
  },
};
</script>
<style lang='scss' scoped>
.outer {
  background: #1a2a60;
  // padding: 20px 0;
}

.SDtip {
  font-size: 30px;
  font-family: FZFengYaSongS-GB;
  font-weight: bold;
  color: #ffffff;
  padding: 0 290px;
  margin-bottom: 80px;
}

.seamless-warp {
  height: 453px;
  overflow: hidden;
}

ul {
  display: flex;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important;

  li {
    list-style: none;

    img {
      width: 508px;
      height: 100%;
      margin: 0 80px;
    }
  }
}

.imgList {
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  img {
    // width: 100%;
    height: 650px;
    object-fit: initial !important;
  }
}
</style>